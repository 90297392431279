import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import type { IKeycloakBasedAuth, LoginResponse } from './type';
import { AuthenticationError, RequiredActions } from './type';
import type { TokenPayload } from '@pflegenavi/shared/utils';
import { getTokenPayloadFromToken } from '@pflegenavi/shared/utils';
import { getFetchOptions } from './utils/getFetchOptions';

// Network connectivity provider interface
export interface NetworkInfoProvider {
  isConnected(): Promise<boolean>;
  addConnectionListener(listener: (isConnected: boolean) => void): () => void;
}

// Error reporting interface (e.g., for Sentry)
export interface ErrorReporter {
  captureException(error: Error, context?: Record<string, any>): void;
  captureMessage(message: string, context?: Record<string, any>): void;
}

function setApplicationHeaders(
  options?: RequestInit & { headers?: Headers }
): RequestInit & { headers?: Headers } {
  // @ts-expect-error //global is untyped
  const application = window.global.pflegenaviApplication;
  // @ts-expect-error //global is untyped
  const applicationVersion = window.global.pflegenaviApplicationVersion;
  // @ts-expect-error //global is untyped
  const tenant = window.global.tenant;
  const headers = options?.headers ?? new Headers();
  headers.set('X-PN-Application', application);
  headers.set('X-PN-Application-Version', applicationVersion);
  if (application === 'service-provider-web') {
    headers.set('X-PN-StripeMode', 'live');
  }
  if (tenant) {
    headers.set('X-PN-Tenant', tenant);
  }
  const optionsWithApplication = {
    ...options,
    headers,
  };

  return optionsWithApplication;
}

const Context = createContext<IKeycloakBasedAuth | undefined>(undefined);

interface Storage {
  setItem: (key: string, value: string) => Promise<void> | void;
  getItem: (key: string) => Promise<string | null> | string | null;

  removeItem(key: string): void;
}

interface PflegenaviAuthenticationProviderProps {
  storage: Storage;
  getWelcomeToken: () => Promise<string | null>;
  shouldUseNewLogin: boolean;
  apiUrl: string;
  redirectIfNecessary: (url: string) => 'redirected' | undefined;
  /**
   * Allow to register cleanup functions when the user logs out.
   */
  onLogout: () => void;
  /**
   * Optional network info provider for handling online/offline scenarios
   */
  networkInfoProvider?: NetworkInfoProvider;
  /**
   * Optional error reporter for capturing authentication errors (e.g., Sentry)
   */
  errorReporter?: ErrorReporter;
}

const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const PflegenaviAuthenticationProvider: FC<
  PropsWithChildren<PflegenaviAuthenticationProviderProps>
> = ({
  children,
  storage,
  getWelcomeToken,
  shouldUseNewLogin,
  apiUrl,
  redirectIfNecessary,
  onLogout,
  networkInfoProvider,
  errorReporter,
}) => {
  const [, rerender] = useState({});

  const auth = useMemo(
    () =>
      new PflegenaviAuthentication(
        apiUrl,
        () => rerender({}),
        storage,
        redirectIfNecessary,
        onLogout,
        networkInfoProvider,
        errorReporter
      ),
    // On mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // TODO ensure initialization can be multiple times for each token
  useEffect(() => {
    void auth.initialize(getWelcomeToken);
    return () => {
      auth.cleanup();
    };
  }, [auth, getWelcomeToken]);

  if (!shouldUseNewLogin) {
    return children;
  }

  return <Context.Provider value={auth.context()}>{children}</Context.Provider>;
};

export function useAuthentication<T extends boolean>(
  shouldUseNewLogin: T
): T extends true ? IKeycloakBasedAuth : IKeycloakBasedAuth | undefined {
  const auth = useContext(Context);
  if (!auth && shouldUseNewLogin) {
    throw new Error('No authentication provider found');
  }
  // @ts-expect-error // can't type due to dependency on shouldUseNewLogin
  return auth;
}

class PflegenaviAuthentication {
  private logger: undefined | typeof console = undefined;
  private access_token: string | undefined;

  private required_actions: RequiredActions[] = [];
  private payment_processor: 'stripe' | 'mangopay' | undefined;
  private required_action_token: string | undefined;

  private error: string | undefined;
  private errorOptions:
    | {
        invitationWasValidFor?: string;
      }
    | undefined;
  private redirectUri: string | undefined;

  private initializing = true;

  private refreshTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  private retryCount = 0;
  private maxRetries = 5;
  private isNetworkConnected = true;
  private connectionListenerCleanup: (() => void) | undefined;

  // Track the last 10 refresh tokens used
  private usedRefreshTokens: string[] = [];
  private maxTrackedTokens = 10;

  /**
   Promise to avoid running multiple operations at the same time.
   */
  private promise: any;

  constructor(
    private baseUrl: string,
    private rerender: () => void,
    private storage: Storage,
    private redirectIfNecessary: (hostname: string) => 'redirected' | undefined,
    private onLogout: () => void,
    private networkInfoProvider?: NetworkInfoProvider,
    private errorReporter?: ErrorReporter
  ) {}

  private getHeaders(otherHeaders: Headers) {
    const headers =
      setApplicationHeaders({
        headers: otherHeaders,
      }).headers ?? new Headers();

    if (this.access_token) {
      headers.set('Authorization', `Bearer ${this.access_token}`);
    }

    return headers;
  }

  private clearTimer() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = undefined;
    }
  }

  private setError(
    error: string,
    options?: {
      [key: string]: string;
    }
  ) {
    this.logger?.info('Error', error);
    this.access_token = undefined;
    this.required_actions = [];
    this.required_action_token = undefined;
    this.error = error;
    this.errorOptions = options;
    this.redirectUri = undefined;
    this.clearTimer();
    this.storage.removeItem(REFRESH_TOKEN);
  }

  private setRequiredActions(
    actions: { rqac: RequiredActions[]; pp: 'stripe' | 'mangopay' },
    token: string
  ) {
    this.access_token = undefined;
    this.required_actions = actions.rqac;
    this.payment_processor = actions.pp;
    this.required_action_token = token;
    this.error = undefined;
    this.redirectUri = undefined;
    this.storage.removeItem(REFRESH_TOKEN);
    this.clearTimer();
  }

  private setTokens(
    accessToken: string,
    refreshToken: string,
    redirectUri: string | undefined,
    refreshInSeconds: number,
    paymentProcessor: 'stripe' | 'mangopay'
  ) {
    this.access_token = accessToken;
    this.required_actions = [];
    this.required_action_token = undefined;
    this.payment_processor = paymentProcessor;
    this.error = undefined;
    this.redirectUri = redirectUri;
    this.storage.setItem(REFRESH_TOKEN, refreshToken);

    this.clearTimer();
    this.refreshTimer = setTimeout(
      this.updateToken.bind(this),
      refreshInSeconds * 1000
    );
  }

  private reset() {
    this.onLogout();
    this.access_token = undefined;
    this.required_actions = [];
    this.required_action_token = undefined;
    this.error = undefined;
    this.redirectUri = undefined;
    this.storage.removeItem(REFRESH_TOKEN);
    this.clearTimer();
  }

  initialize(getWelcomeToken: () => Promise<string | null>): Promise<any> {
    // Set up network connectivity listener if provider is available
    if (this.networkInfoProvider) {
      // Initialize network status
      void this.networkInfoProvider.isConnected().then((connected) => {
        this.isNetworkConnected = connected;
      });

      // Set up listener for network changes
      this.connectionListenerCleanup =
        this.networkInfoProvider.addConnectionListener((connected) => {
          const wasDisconnected = !this.isNetworkConnected;
          this.isNetworkConnected = connected;

          // If we've regained connectivity and we have a pending token refresh,
          // attempt to refresh the token now
          if (connected && wasDisconnected && this.access_token) {
            // Clear existing timer and try immediately
            this.clearTimer();
            void this.updateToken();
          }
        });
    }

    return this.waitWithPromise(
      this.initializeInner.bind(this, getWelcomeToken)
    );
  }

  async initializeInner(getWelcomeToken: () => Promise<string | null>) {
    this.logger?.info('Initialize');
    const token = await getWelcomeToken();

    this.logger?.info('Weclome token: ', token);
    if (token) {
      // Logout the user in case someone was logged in.
      this.reset();
      const payload = getTokenPayloadFromToken<
        {
          rqac: RequiredActions[];
          pp: 'stripe' | 'mangopay';
        } & TokenPayload
      >(token);

      if (!payload || payload.exp * 1000 < new Date().valueOf()) {
        if (payload?.exp && payload?.iat) {
          const invitationWasValidFor =
            (payload.exp - payload.iat) / 60 / 60 / 24; // From seconds to days
          this.setError(AuthenticationError.InvitationExpired, {
            invitationWasValidFor: invitationWasValidFor.toString(),
          });
        }
      } else if (payload.typ === 'refresh_token') {
        this.logger?.info('Refresh token - updating');
        await this.updateTokenInner(token);
      } else {
        this.logger?.info('Valid welcome token', payload.rqac);
        const rqac = payload.rqac ?? [];
        this.setRequiredActions(
          {
            rqac: rqac,
            pp: payload.pp,
          },
          token
        );

        if (
          payload.typ === 'verify_email' &&
          this.required_actions.includes(RequiredActions.VERIFY_EMAIL)
        ) {
          this.logger?.info('Verifying email');
          await this.verifyEmail();
        } else {
          // Refresh the token
          await this.loginWithToken();
        }
      }
    } else {
      const refreshToken = await this.storage.getItem(REFRESH_TOKEN);
      this.logger?.info('Retrieved refresh token', refreshToken);

      if (refreshToken) {
        this.logger?.info('Refreshing token');
        await this.updateTokenInner(refreshToken);
      }

      // If we impersonate, we will attempt to login.
      // The authorization will be based on cookies.
      if (window?.location?.search?.includes('impersonate')) {
        await this.loginWithToken();
      }
    }

    this.initializing = false;
    this.logger?.info('Finished initializing');
    this.rerender();
  }

  private loginUrl() {
    return `${this.baseUrl}/api/users/log_in`;
  }

  private logoutUrl() {
    return `${this.baseUrl}/api/users/log_out`;
  }

  private resetPasswordUrl() {
    return `${this.baseUrl}/api/users/reset_password`;
  }

  private verifyEmailUrl() {
    return `${this.baseUrl}/api/users/verify_email`;
  }

  private acceptTermsAndConditionsUrl() {
    return `${this.baseUrl}/api/users/terms_and_conditions`;
  }

  private refreshTokenUrl() {
    return `${this.baseUrl}/api/users/token/refresh`;
  }

  context(): IKeycloakBasedAuth {
    return {
      login: this.login.bind(this),
      logout: this.logout.bind(this),
      loadUserProfile: this.loadUserProfile.bind(this),
      loginWithToken: this.loginWithToken.bind(this),
      updateToken: this.updateToken.bind(this, undefined),
      getTokenImmediate: this.getTokenImmediate.bind(this),
      token: this.access_token,
      paymentProcessor: this.payment_processor,
      resetPassword: this.resetPassword.bind(this),
      resetPasswordConfirm: this.resetPasswordConfirm.bind(this),
      error: this.error,
      errorOptions: this.errorOptions,
      requiredActions: this.required_actions,
      redirectUri: this.redirectUri,
      initializing: this.initializing,
      acceptTermsAndConditions: this.acceptTermsAndConditions.bind(this),
      cleanup: this.cleanup.bind(this),
    };
  }

  async handleLoginResponse(
    result: Response
  ): Promise<LoginResponse | undefined> {
    if (result.status === 200) {
      const json = await result.json();
      const token = json.data.required_action_token;
      if (token) {
        const payload = getTokenPayloadFromToken<
          {
            rqac: RequiredActions[];
            pp: 'stripe' | 'mangopay';
          } & TokenPayload
        >(token);
        if (!payload) {
          return {
            status: 'error',
            errorMessage: 'Invalid token',
          };
        }

        this.setRequiredActions(payload, token);

        return {
          required_actions: payload.rqac as RequiredActions[],
        };
      } else {
        const payload = getTokenPayloadFromToken(json.data.access_token);

        const refreshInSeconds = !payload?.exp
          ? 600
          : (payload.exp * 1000 - new Date().valueOf()) / 1000 - 10;

        if (payload?.reduri) {
          const redirected = this.redirectIfNecessary(
            `${payload.reduri}?key=${json.data.refresh_token}`
          );
          if (redirected) {
            return { status: 'OK' };
          }
        }

        this.setTokens(
          json.data.access_token,
          json.data.refresh_token,
          payload?.reduri,
          refreshInSeconds,
          payload?.pp ?? 'stripe'
        );

        return { status: 'OK' };
      }
    }
    return undefined;
  }

  /**
   * Runs a function if no other promised function is currently running
   */
  async waitWithPromise<T>(callback: () => Promise<T>) {
    if (this.promise) {
      this.promise = this.promise.then(callback);
    } else {
      this.promise = callback();
    }

    const thisPromise = this.promise;
    const result = await thisPromise;
    // this.promise might have changed while we waited for thisPromise
    if (this.promise === thisPromise) {
      this.promise = undefined;
    }
    return result;
  }

  /**
   * Runs a function if no other promised function is currently running
   */
  async discardWithPromise<T>(callback: () => Promise<T>) {
    if (this.promise) {
      await this.promise;
      return undefined;
    }

    this.promise = callback();

    const thisPromise = this.promise;
    const result = await thisPromise;
    if (this.promise === thisPromise) {
      this.promise = undefined;
    }
    return result;
  }

  login(email: string, password: string): Promise<LoginResponse> {
    // Using discard as multiple logins are not allowed -
    // and if we are updating or logging out, we might not need to login again.
    return this.discardWithPromise(this.loginInner.bind(this, email, password));
  }

  async loginInner(email: string, password: string): Promise<LoginResponse> {
    const options = getFetchOptions();
    const fetchResult = await fetch(this.loginUrl(), {
      method: 'POST',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        user: {
          email,
          password,
        },
      }),
    });

    const result = await this.handleLoginResponse(fetchResult);
    this.rerender();
    if (!result || fetchResult.status !== 200) {
      const resultBody = await fetchResult.json().then((json) => json);
      return {
        status: 'error',
        errorMessage: resultBody.errors.detail,
      };
    }

    return result;
  }

  async loginWithToken() {
    const options = getFetchOptions();
    const fetchResult = await fetch(this.loginUrl(), {
      method: 'POST',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        user: {
          token: this.required_action_token,
        },
      }),
    });

    this.logger?.info('Login with token', fetchResult);

    const result = await this.handleLoginResponse(fetchResult);
    if (!result || fetchResult.status === 422) {
      this.setError('Invalid token');
      this.rerender();
      return { status: 'error' };
    }

    this.rerender();
    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  loadUserProfile(): Promise<Keycloak.KeycloakProfile> {
    // @ts-expect-error // TODO: Seems to be unused
    return Promise.resolve(undefined);
  }

  logout(unused: { redirectUri?: string }): Promise<void> {
    // Waiting as we should ensure logouts actually happen
    return this.waitWithPromise(this.logoutInner.bind(this, unused));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async logoutInner(_unused: { redirectUri?: string }): Promise<void> {
    if (!this.access_token && !this.storage.getItem(REFRESH_TOKEN)) {
      // Already logged out. Resetting to be sure.
      this.reset();
      return Promise.resolve(undefined);
    }
    const options = getFetchOptions();
    await fetch(this.logoutUrl(), {
      method: 'DELETE',
      ...options,
      headers: this.getHeaders(options.headers),
    });

    this.reset();

    this.rerender();
    return Promise.resolve(undefined);
  }

  updateToken(refreshToken?: string): Promise<boolean> {
    // Discarding as we should only update once.
    return this.discardWithPromise(
      this.updateTokenInner.bind(this, refreshToken)
    );
  }

  getTokenImmediate(): string | undefined {
    return this.access_token;
  }

  async updateTokenInner(refreshToken?: string): Promise<boolean> {
    const options = getFetchOptions();
    const refresh_token =
      refreshToken ?? (await this.storage.getItem(REFRESH_TOKEN));

    if (!refresh_token) {
      this.errorReporter?.captureMessage(
        'Authentication failed: No refresh token available',
        {
          tags: { source: 'auth_flow', event: 'token_refresh_failed' },
        }
      );
      await this.logoutInner({});
      return false;
    }

    // Check network connectivity if we have a provider
    if (this.networkInfoProvider) {
      this.isNetworkConnected = await this.networkInfoProvider.isConnected();

      // If we're offline, schedule a retry and keep the user logged in
      if (!this.isNetworkConnected) {
        this.logger?.log('Network offline, deferring token refresh');
        this.clearTimer();
        this.refreshTimer = setTimeout(
          this.updateToken.bind(this, undefined),
          30000 // Try again in 30 seconds
        );
        return Boolean(this.access_token);
      }
    }

    // Check if this token was already used recently
    if (this.usedRefreshTokens.includes(refresh_token)) {
      this.logger?.log(
        'Refresh token was already used, assuming it was updated'
      );
      // Return true to indicate we still have a valid token
      return Boolean(this.access_token);
    }

    // Add this token to the used tokens list
    this.usedRefreshTokens.push(refresh_token);
    // Keep only the most recent tokens
    if (this.usedRefreshTokens.length > this.maxTrackedTokens) {
      this.usedRefreshTokens.shift(); // Remove the oldest token
    }

    try {
      const controller = new AbortController();

      const abort = setTimeout(() => controller.abort(), 30000);

      const result = await fetch(this.refreshTokenUrl(), {
        method: 'POST',
        ...options,
        headers: this.getHeaders(options.headers),
        body: JSON.stringify({
          refresh_token,
        }),
        signal: controller.signal,
      });

      clearTimeout(abort);

      const response = await this.handleLoginResponse(result);
      if (
        (response && 'status' in response && response.status === 'error') ||
        result.status !== 200
      ) {
        this.errorReporter?.captureMessage(
          'Authentication failed: Token refresh request failed',
          {
            tags: { source: 'auth_flow', event: 'token_refresh_failed' },
            extra: {
              status: result.status,
              response: response ? JSON.stringify(response) : undefined,
            },
          }
        );
        await this.logoutInner({});
      }
      // Reset retry count on success
      this.retryCount = 0;
      this.rerender();
      return Boolean(response);
    } catch (error) {
      this.logger?.error('Token refresh failed due to network error:', error);

      // Allow to re-use the refresh token if the network error is due to a temporary issue
      this.usedRefreshTokens = this.usedRefreshTokens.filter(
        (token) => token !== refresh_token
      );

      // Report to Sentry if available
      if (this.errorReporter) {
        this.errorReporter.captureException(
          error instanceof Error
            ? error
            : new Error('Token refresh network error'),
          {
            tags: { source: 'auth_flow', event: 'token_refresh_network_error' },
            extra: {
              retryCount: this.retryCount,
              maxRetries: this.maxRetries,
            },
          }
        );
      }

      // Increment retry count and implement exponential backoff
      this.retryCount = Math.min(this.retryCount + 1, this.maxRetries);
      const backoffTime = Math.min(1000 * 2 ** this.retryCount, 300000); // Max 5 minutes

      // Schedule another refresh attempt with exponential backoff
      this.clearTimer();
      this.refreshTimer = setTimeout(
        this.updateToken.bind(this, undefined),
        backoffTime
      );

      // Return true to indicate we still have a valid token
      // We're just unable to refresh it at the moment
      return Boolean(this.access_token);
    }
  }

  async resetPassword(email: string): Promise<Response> {
    const options = getFetchOptions();
    const result = await fetch(this.resetPasswordUrl(), {
      method: 'POST',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        user: {
          email,
        },
      }),
    });

    return result;
  }

  async resetPasswordConfirm(
    token: string | undefined,
    body: {
      password: string;
      passwordConfirmation: string;
    }
  ): Promise<Response> {
    token = this.required_action_token || token;
    if (!token) {
      throw new Error('Missing token to confirm password reset.');
    }
    const options = getFetchOptions();
    const result = await fetch(`${this.resetPasswordUrl()}/${token}`, {
      method: 'PUT',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        user: {
          password: body.password,
          password_confirmation: body.passwordConfirmation,
        },
      }),
    });

    // If the user has required actions, we attempt to login with the token
    if (this.required_action_token) {
      await this.loginWithToken();
    }

    return result;
  }

  async acceptTermsAndConditions(): Promise<Response> {
    const token = this.required_action_token;
    if (!token) {
      throw new Error('Missing token to accept terms and conditions.');
    }
    const options = getFetchOptions();
    const result = await fetch(
      `${this.acceptTermsAndConditionsUrl()}/${token}`,
      {
        method: 'PUT',
        ...options,
        headers: this.getHeaders(options.headers),
      }
    );

    await this.loginWithToken();

    return result;
  }

  async verifyEmail(): Promise<Response> {
    const token = this.required_action_token;
    if (!token) {
      throw new Error('Missing token to verify email.');
    }
    const options = getFetchOptions();
    const result = await fetch(`${this.verifyEmailUrl()}/${token}`, {
      method: 'PUT',
      ...options,
      headers: this.getHeaders(options.headers),
    });

    this.logger?.info('Verify email result', result);

    await this.loginWithToken();

    return result;
  }

  // Clean up resources when component is unmounted
  cleanup() {
    this.clearTimer();
    if (this.connectionListenerCleanup) {
      this.connectionListenerCleanup();
      this.connectionListenerCleanup = undefined;
    }
  }
}
